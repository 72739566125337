<template>
  <div style="text-align: center">
    <headerMenu></headerMenu>
    <v-card
        class="d-flex flex-column"
        color="grey lighten-2"
        flat
        tile
        height="100"
        style="text-align: right"
    >
    </v-card>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" style="position:absolute;top:50%;left:50%;transform: translate(-50%, -50%)">
          <h4><b>Bifidobacterium</b></h4>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" style="position:absolute;top:50%;left:50%;transform: translate(-50%, -50%)">
          Genus 나이별 다양성
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" >
          <v-chart :options="bifiChartData" ref="bifiChart" :initOptions="chartSize"/>
        </v-row>
      </v-col>
    </v-row>
    <br><br><br><br><br>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" style="position:absolute;top:50%;left:50%;transform: translate(-50%, -50%)">
          <h4><b>Bifidobacterium</b></h4>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" style="position:absolute;top:50%;left:50%;transform: translate(-50%, -50%)">
            Species 별 분석
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" >
          <v-chart :options="bifiSpeciesChart" :initOptions="chartSize"/>
        </v-row>
      </v-col>
    </v-row>
    <br><br><br><br><br><br>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" style="position:absolute;top:50%;left:50%;transform: translate(-50%, -50%)">
          <h4><b>Lactobacillus</b></h4>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" style="position:absolute;top:50%;left:50%;transform: translate(-50%, -50%)">
          Genus 나이별 다양성
        </v-row>
      </v-col>
    </v-row>
    <vs-row vs-justify="center">
      <vs-col vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12" style="border-bottom:10px">
        <div>
          <h4><b>  <v-chart :options="lactoGenusChartData"  :init-options="chartSize" /></b></h4>
        </div>
      </vs-col>
    </vs-row>
    <br><br><br><br><br>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" style="position:absolute;top:50%;left:50%;transform: translate(-50%, -50%)">
          <h4><b>Lactobacillus</b></h4>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" style="position:absolute;top:50%;left:50%;transform: translate(-50%, -50%)">
          Species 별 분석
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" >
          <v-chart :options="lactoSpeciesChart" :initOptions="chartSize"/>
        </v-row>
      </v-col>
    </v-row>
    <div v-show="false">{{code}}</div>
    <div @click="reload()">
      <fab
          :position="'bottom-right'"
          :bg-color="'#0f7299'"
          :main-icon="'cached'"
          :ripple-color="'dark'"
          :icon-size="'small'"

      >
      </fab>
    </div>
  </div>
</template>

<script>
import headerMenu from '../components/HeaderMenu'
import { mapActions } from 'vuex'
import fab from 'vue-fab'

export default {
  components: {
    headerMenu,
    fab
  },
  name: 'lactodetail.vue',
  data() {
    return {
      active : false,
      chartSize : {
        width: window.innerWidth,
        height: 500
      },
    }
  },
  methods: {
    ...mapActions(['lactoDetail']),
/*
    async updateChart(){
      console.log("update chart");
      let lactoDetailData = await this.lactoDetail();
      this.bifiChartData = lactoDetailData.diversity.bifi;
      this.lactoGenusChartData = lactoDetailData.diversity.lacto;
      this.bifiSpeciesChart = lactoDetailData.speciesDetail.bifi;
      this.lactoSpeciesChart = lactoDetailData.speciesDetail.lacto;

    },
*/
    async reload(){
      this.chartSize = {
        width: window.innerWidth,
        height: 500
      }
    }
  },
  mounted () {
    //this.updateChart();
  },
  computed: {
    code : {
      get() {
        console.log("call lactoDetail Get Code:", this.$store.state.code)
        //this.updateChart();
        return this.$store.state.code
      }
    },
/*
    overlayLoading: function(){
     return this.$store.state.overlayLoading;
    },
*/
    lactoSpeciesChart: function(){
     return this.$store.state.lactoDetail.diversity.bifi;
    },
    bifiSpeciesChart: function() {
      return  this.$store.state.lactoDetail.diversity.lacto;
    },
    bifiChartData: function() {
      return this.$store.state.lactoDetail.speciesDetail.bifi;
    },
    lactoGenusChartData: function() {

      return this.$store.state.lactoDetail.speciesDetail.lacto;
    }

  }
}
</script>

<style scoped>

</style>