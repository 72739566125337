<template>
  <div>

  <vs-navbar :collapse="false"  v-model="active" class="fixed-top menu-static"
             ref="parentSidebar"
             text-color="rgba(255,255,255,.6)"
             active-text-color="rgba(255,255,255,1)"
             :color="`#8CC152`" >

    <div slot="title">
      <vs-navbar-title>
        <vs-row vs-justify="center">
          <vs-col  vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
            <div style="margin-top: 6px">
              <vs-select
                  autocomplete
                  class="selectExample"
                  v-model="code"
                  style="width: 300px"
              >
                <vs-select-item :key="index" :value="item.value" :text="item.text"
                                v-for="(item,index) in codeList"/>
              </vs-select>
            </div>
          </vs-col>
          <!--
                                        <vs-col  vs-justify="center" vs-align="center" align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                            <div style="margin-top: 6px">
                                                <img src="/img/aib_logo.png" style="height: 35%; width: 35%"   />
                                            </div>
                                        </vs-col>
          -->
        </vs-row>
      </vs-navbar-title>
    </div>

    <vs-navbar-item index="3">
      <router-link to="/home">Home</router-link>
    </vs-navbar-item>

    <vs-navbar-item index="0" >
      <a href="#">유산균 상세 분석</a>
    </vs-navbar-item>


    <vs-navbar-item index="1" class="mr-10">
      <router-link to="/users" class="pr-10">사용자 관리</router-link>
    </vs-navbar-item>

    <vs-navbar-item index="7">
      <a href="/v1/logout">로그아웃</a>
    </vs-navbar-item>

    <vs-navbar-item index="2">
    </vs-navbar-item>
    <vs-spacer></vs-spacer>
    <vs-button color-text="rgb(255, 255, 255)" color="rgba(255, 255, 255, 0.3)" type="flat" ></vs-button>
  </vs-navbar>
    <v-overlay :value="overlayLoading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      active : 0
    }
  },
  methods : {
    ...mapActions(['getSample']),
  },
  computed : {
    code :  {
      get() {
        console.log("call header Get Code:", this.$store.state.code)
        return this.$store.state.code
      },
      set(code) {
        console.log("Call header Set Code:", code);
        this.$store.commit('setCode', code);
        this.getSample();
      }
    },
    overlayLoading: function(){
      return this.$store.state.overlayLoading;
    },
    codeList : function () {
      return this.$store.state.codeList;
    }
  }
}
</script>

<style scoped>

</style>